import { ref } from 'vue';

interface TippyInstance {
    hide: () => void;
    show: () => void;
    destroy: () => void;
    props?: {
        interactive?: boolean;
    }
    reference?: Element;
    // Add other methods as needed
}

// Global registry for Tippy instances
const allTippyInstances = ref<TippyInstance[]>([]);

export const hideAllTippies = () => {
    allTippyInstances.value.forEach((instance) => {
        instance.hide();
    });
};

export const registerTippyInstance = (instance: TippyInstance) => {
    if (!allTippyInstances.value.includes(instance)) {
        allTippyInstances.value.push(instance);
    }
};

export const unregisterTippyInstance = (instance: TippyInstance) => {
    const index = allTippyInstances.value.indexOf(instance);
    if (index > -1) {
        allTippyInstances.value.splice(index, 1);
    }
};

export const assignTippyButtonRole = (instance: TippyInstance) => {
    const { props, reference } = instance;

    if (props?.interactive && reference instanceof HTMLElement) {
        reference.setAttribute('role', 'button');
    }
};
